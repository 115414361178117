import ActionCable from "actioncable"
import * as types from "../store/mutation-types"
import store from "../store"
import jsonApii from "../helpers/json_apii"
import i18n from "../plugins/i18n"

const webSocketProtocol = process.env.NODE_ENV === "production" ? "wss" : "ws"
const baseURL = window.location.href.split("/")[2]
const cable = ActionCable.createConsumer(webSocketProtocol + "://" + baseURL + "/cable")

function commit(type, data) {
  store.commit(type, data, { root: true })
}

function newAppNotification(message, type) {
  store.dispatch("newAppNotification", { message, type })
}

function currentUser() {
  return store.getters.currentUser
}

function checkIfSubscriptionExists(subscriptions, channelName, subscriptionParam) {
  if (!subscriptions.length)
    return false
  return subscriptions.some(sub =>
    Object.values(JSON.parse(sub.identifier))[0] === channelName &&
    Object.values(JSON.parse(sub.identifier))[1] === subscriptionParam)
}

let subscriptions = {}
const channels = {

  TeachersQuiz: {
    subscribe({ quizId } ) {
      if (checkIfSubscriptionExists(cable.subscriptions.subscriptions, "TeachersQuizChannel", quizId))
        return
      subscriptions.TeachersQuiz =  cable.subscriptions.create(
        { channel: "TeachersQuizChannel", quiz_id: quizId },
        {
          received (data) {
            // console.log({ TeachersQuizChannel: data })
            // if (data.data?.type === "classroom") {
            //   commit(
            //     types.UPDATE_CLASSROOM,
            //     jsonApii.getData(data)
            //   )
            // }
            if (data.data?.type === "quiz") {
              commit(
                types.CREATE_OR_UPDATE_QUIZZ,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "score") {
              console.log(data.data)
              if (data?.action === "update") {
                commit(
                  types.CREATE_OR_UPDATE_SCORE,
                  jsonApii.getData(data)
                )
              } else if (data?.action === "destroy") {
                commit(
                  types.DELETE_SCORE,
                  jsonApii.getData(data)
                )
              }
            }
            if (data.data?.type === "score_summary") {
              if (data?.action === "update") {
                commit(
                  types.CREATE_OR_UPDATE_SCORE,
                  jsonApii.getData(data)
                )
              } else if (data?.action === "destroy") {
                commit(
                  types.DELETE_SCORE,
                  jsonApii.getData(data)
                )
              }
            }
            if (data.data?.type === "student_event") {
              commit(
                types.CREATE_OR_UPDATE_STUDENT_EVENT,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "question") {
              // Todo: this is a temp option while question stats and question module
              // are split
              commit(types.UPDATE_QUESTION_STATS, jsonApii.getData(data))
              commit(types.CREATE_OR_UPDATE_QUESTION, jsonApii.getData(data))
            }
            if (data.data?.type === "email") {
              commit(
                types.UPDATE_EMAIL,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "scanned_sheet") {
              commit(
                types.UPDATE_SCAN_SHEET,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "scanned_paper_quiz") {
              commit(
                types.UPDATE_SCAN_PAPER,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "message") {
              commit(
                types.UPDATE_CHAT_MESSAGE,
                jsonApii.getData(data)
              )
              if (data.data?.attributes?.status === "sent" && data.data?.attributes?.user_id != currentUser()?.id)
                newAppNotification(i18n.t("quiz.evalmeeChat.newMessage"), "info")
            }
            if (data.data?.type === "channel") {
              commit(
                types.UPDATE_CHAT_CHANNEL,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "task_progress_manager") {
              commit(
                types.UPDATE_TASK_PROGRESS_MANAGER,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "similarity_check_submission") {
              commit(
                types.UPDATE_SIMILARITY_CHECK_SUBMISSION,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "quizzes_stat") {
              commit(
                types.UPDATE_QUIZZES_STAT,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "paper_quiz") {
              commit(
                types.UPDATE_PAPER_QUIZ,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "quizzes_session") {
              commit(
                types.CREATE_OR_UPDATE_QUIZ_SESSION,
                jsonApii.getData(data)
              )
            }
          },
        }
      )
    },
  },

  TeachersScores: {
    subscribe({ quizId, scoreIds } ) {
      if (checkIfSubscriptionExists(cable.subscriptions.subscriptions, "TeachersScoresChannel", quizId))
        return
      subscriptions.TeachersScores =  cable.subscriptions.create(
        { channel: "TeachersScoresChannel", quiz_id: quizId },
        {
          // `connected` is called when the subscription is ready for use on the server
          connected() {
            this.followScores(scoreIds)
          },
          received (data) {
            if (data.data?.type === "score_summary") {
              if (data?.action === "update") {
                commit(
                  types.CREATE_OR_UPDATE_SCORE,
                  jsonApii.getData(data)
                )
              } else if (data?.action === "destroy") {
                commit(
                  types.DELETE_SCORE,
                  jsonApii.getData(data)
                )
              }
            }
            if (data.data?.type === "student_event") {
              commit(
                types.CREATE_OR_UPDATE_STUDENT_EVENT,
                jsonApii.getData(data)
              )
            }
          },
          followScores(scoreIds) {
            this.perform("follow_scores", { score_ids: scoreIds })
          },
        }
      )
    },
  },

  StudentsQuiz: {
    subscribe({ quizId }) {
      if (checkIfSubscriptionExists(cable.subscriptions.subscriptions, "StudentsQuizChannel", quizId))
        return
      cable.subscriptions.create(
        { channel: "StudentsQuizChannel", quiz_id: quizId },
        {
          received (data) {
            if (data.data?.type === "quiz") {
              commit(
                types.CREATE_OR_UPDATE_QUIZZ,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "message") {
              commit(
                types.UPDATE_CHAT_MESSAGE,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "channel") {
              commit(
                types.UPDATE_CHAT_CHANNEL,
                jsonApii.getData(data)
              )
            }
          },
        }
      )
    },
  },

  User: {
    subscribe({ userId }) {
      if (checkIfSubscriptionExists(cable.subscriptions.subscriptions, "UserChannel", userId))
        return
      cable.subscriptions.create(
        { channel: "UserChannel", user_id: userId },
        {
          received (data) {
            if (data.data?.type === "score") {
              commit(
                types.CREATE_OR_UPDATE_SCORE,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "quizzes_attempt_summary") {
              commit(
                types.CREATE_OR_UPDATE_QUIZZES_ATTEMPT_SUMMARY,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "answer") {
              commit(
                types.CREATE_OR_UPDATE_ANSWER2,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "instance") {
              commit(
                types.CREATE_OR_UPDATE_QUIZ_INSTANCE,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "message") {
              commit(
                types.UPDATE_CHAT_MESSAGE,
                jsonApii.getData(data)
              )
            }
            if (data.data?.type === "channel") {
              commit(
                types.UPDATE_CHAT_CHANNEL,
                jsonApii.getData(data)
              )
            }
          },
        }
      )
    },
  },

  QuizStudentEvents: {
    subscribe({ quizId }) {
      if (checkIfSubscriptionExists(cable.subscriptions.subscriptions, "QuizzesStudentEventsChannel", quizId))
        return
      let QuizStudentEvents = cable.subscriptions.create(
        { channel: "QuizzesStudentEventsChannel", quiz_id: quizId },
        {
          received (data) {
            console.log({ QuizzesStudentEventsChannel: data })
          },
        }
      )

      subscriptions.QuizStudentEvents = QuizStudentEvents
    },
  },
}

export default {
  cable,
  channels,
  subscriptions,
}
